import React from 'react';
import styled from '@emotion/styled';
import { color, device } from '../../constants';
import { Intro, HowWeReview, EmailCopy } from './copy';
import { Weightings, Email } from './components';
import { H2, H3 } from './title';
import Container from './container';

const Body = styled.p`
  max-width: 90%;
  margin-top: 10px;

  font-size: 18px;
  white-space: pre-wrap;

  color: ${color.white};

  h3 {
    color: ${color.white};
    margin-bottom: 10px;

    @media ${device.tablet} {
      margin-bottom: 20px;
    }
  }

  table {
    max-width: 100px;
  }

  @media ${device.tablet} {
    max-width: 661px;
  }
`;

export default () => (
  <Container>
    <H2>About Us</H2>
    <Body className="Body1">
      <Intro />
      <EmailCopy />
      <Email />
      <H3>How We Review</H3>
      <HowWeReview />
      <h4>Weightings</h4>
      <Weightings />
    </Body>
  </Container>
);
