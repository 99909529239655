import styled from '@emotion/styled';
import { color, device } from '../../constants';

export default styled.div`
  background-color: ${color.baggyGreen};

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    min-height: 184px;
  }

  @media ${device.laptopL} {
    min-height: 200px;
    max-width: 1200px;
  }
`;
