export const Intro = () => `B + A have been best friends since their teens. Having grown up together in countryside New South Wales, Australia they eventually relocated to other halves of the world - A to California and B + T to London.

Between the three of them, they make quite the group;
A, a design lead at one of San Francisco's hottest startups.
B, a software engineer by day and night.
T, a corporate lawyer and expert in all things grammar.

Together they set out to create a vibrant, easy-to-use and unpretentious website based on their own experiences. A, B, + T understand that our palates, and the reasons we gather to drink wine, are diverse.

75cl seeks to strip away the snobbery often associated with wine, and to start a relaxed conversation about where and what you might like to gulp, guzzle, slurp and sip.

`;

export const EmailCopy = () => `If you have any feedback, please email us at `;

export const HowWeReview = () => `We know that preferences and palates differ, and that the places we review could very clearly, or perhaps only loosely, fall into the category of a ‘wine bar’. We like to keep an open mind and try new things, so if there’s decent - or not-so-decent - wine to be had, we’re happy to give all sorts of bars, restaurants, pubs, pop-ups and stores a whirl.

Each establishment is judged out of 100 (see the breakdown below), with food taken out of the score if none is available or we decide (on that occasion) not to partake.
  
The score out of 100 is divided by 10 for the purposes of the review.`;
