import styled from '@emotion/styled';
import { color, device } from '../../constants';

export const H2 = styled.h2`
  margin-top: 5px;
  margin-bottom: 0px;

  width: 90%;
  max-width: 90%;

  color: ${color.white};

  font-size: 38px;

  @media ${device.tablet} {
    width: 661px;
    max-width: 661px;
    margin-bottom: 0px;

    font-size: 92px;
  }
`;

export const H3 = styled.h3`
  width: 90%;
  max-width: 90%;

  color: ${color.white};

  font-size: 28px;

  @media ${device.tablet} {
    font-size: 48px;
  }
`;
