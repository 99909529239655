import React from 'react';
import styled from '@emotion/styled';
import { color, device } from '../../constants';

const Table = styled.table`
  margin-top: 20px;
  min-width: 290px;

  @media ${device.tablet} {
    min-width: 350px;
    max-width: 661px;
  }
`;

export const Weightings = () => (
  <Table>
    <tr>
      <td>Wines by the Glass/Carafe</td>
      <td>20%</td>
    </tr>
    <tr>
      <td>Wines by the Bottle</td>
      <td>20%</td>
    </tr>
    <tr>
      <td>Food</td>
      <td>20%</td>
    </tr>
    <tr>
      <td>Service</td>
      <td>20%</td>
    </tr>
    <tr>
      <td>Decor</td>
      <td>10%</td>
    </tr>
    <tr>
      <td>Value</td>
      <td>10%</td>
    </tr>
  </Table>
);

export const Email = () => (
  <a
    style={{ color: color.white }}
    href="mailto:hey@seventyfive.cl?Subject=Hey!"
    target="_top"
  >
    hey@seventyfive.cl
  </a>
);
