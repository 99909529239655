import _ from 'lodash';
import React from 'react';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import Body from '../components/About';

export default props => {
  const { citiesList, defaultCity } = props.pageContext;

  const title = 'About';

  return (
    <Layout>
      <Head title={title} />
      <Header citiesList={citiesList} defaultCity={defaultCity} />
      <Body />
    </Layout>
  );
};
